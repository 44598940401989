@import '../node_modules/mapbox-gl/dist/mapbox-gl.css';

html {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
}

body {
  margin: 0;
  padding: 0;
  font-family: -apple-system, BlinkMacSystemFont, Segoe UI, Helvetica, Arial,
    sans-serif, Apple Color Emoji, Segoe UI Emoji;
  color: #333;
  font-size: 15px;
  overflow: hidden;
}

* {
  box-sizing: border-box;
}

a {
  color: teal;
  text-decoration: none;
  text-shadow: 0 0 5px #fff;
  background-image: linear-gradient(
    to bottom,
    rgba(255, 255, 255, 0),
    rgba(255, 255, 255, 0) 50%,
    powderblue 90%,
    rgba(255, 255, 255, 0)
  );
}

abbr {
  color: teal;
  font-weight: bold;
  text-decoration: none;
}

.ib {
  display: inline-block;
}

#map {
  position: absolute;
  top: 0;
  bottom: 0;
  width: 100%;
}

.mapboxgl-ctrl-emoji {
  font-size: 18px;
}
.mapboxgl-map {
  user-select: none;
  -webkit-user-select: none;
}
.mapboxgl-ctrl-group {
  border-radius: 123123px;
  overflow: hidden;
}
.mapboxgl-ctrl-group button {
  width: 44px;
  height: 44px;
}

#logo {
  cursor: pointer;
  position: absolute;
  z-index: 100;
  top: 16px;
  left: 16px;
  filter: drop-shadow(0 1px 3px rgba(0, 0, 0, 0.3));
  transition: all 0.3s;
  outline: 0;
}
#logo:active {
  filter: brightness(0.8) drop-shadow(0 0px 2px rgba(0, 0, 0, 0.3));
}

.sheet {
  position: absolute;
  z-index: 300;
  width: 100%;
  top: 100%;
  max-height: 100%;
  overflow: auto;
  background-color: #fff;
  padding: 16px;
  transition: transform 0.3s ease-out;
  pointer-events: none;
  user-select: none;
  -webkit-user-select: none;
}
.sheet.open {
  box-shadow: 0 -5px rgba(0, 0, 0, 0.1);
  transform: translateY(-100%);
  pointer-events: auto;
  user-select: auto;
  -webkit-user-select: auto;
}
.sheet.open.min {
  transform: translateY(-100px);
  cursor: pointer;
}
.sheet.modal.open {
  box-shadow: 0 0 50vh rgba(0, 0, 0, 0.5);
}
.sheet.open ~ #map .mapboxgl-control-container {
  display: none;
}
.sheet-cover {
  position: absolute;
  z-index: 200;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.4);
  opacity: 0;
  pointer-events: none;
  transition: opacity 0.3s;
}
.sheet.modal.open ~ .sheet-cover {
  opacity: 1;
  pointer-events: auto;
}
.sheet.open:not(.modal) ~ #logo {
  transform: translateY(-100%);
  opacity: 0;
  pointer-events: none;
}
.sheet.open:not(.modal) ~ .sheet-close {
  pointer-events: auto;
  opacity: 1;
  transform: translateY(-50vh);
}
#station.open ~ .sheet-close {
  transform: translateY(-50vh);
}
#station.open.min ~ .sheet-close {
  transform: translateY(-100px) rotate(-90deg);
  opacity: 1;
}
.sheet h1 {
  color: teal;
  padding: 0;
  margin: 0;
  letter-spacing: -0.02em;
}
.sheet h2 {
  color: teal;
  padding: 0;
  margin: 0;
}
.sheet h3 {
  color: teal;
  padding: 0;
  margin: 0;
  font-size: 1em;
}

.sheet .btn {
  cursor: pointer;
  background-color: teal;
  color: #fff;
  border: 0;
  border-radius: 12px;
  padding: 16px;
  display: block;
  width: 100%;
  font-weight: bold;
  font-size: 1em;
  font-family: inherit;
  outline: 0;
}
.sheet .btn:active {
  filter: brightness(0.8);
}

.sheet-close {
  pointer-events: none;
  display: block;
  opacity: 0;
  position: absolute;
  z-index: 301;
  top: 100%;
  right: 16px;
  width: 44px;
  height: 44px;
  border-radius: 123123px;
  border: 0;
  padding: 0;
  background-color: gainsboro;
  transform: translateY(150%) rotate(-90deg);
  cursor: pointer;
  transition-property: transform, opacity;
  transition-duration: 0.3s;
  transition-timing-function: ease-out;
  outline: 0;
}
.sheet-close:after {
  content: '';
  display: block;
  width: 2px;
  height: 20px;
  background-color: gray;
  position: absolute;
  left: calc(50% - 1px);
  top: calc((44px - 20px) / 2);
  transform: rotate(45deg);
}
.sheet-close:before {
  content: '';
  display: block;
  width: 2px;
  height: 20px;
  background-color: gray;
  position: absolute;
  left: calc(50% - 1px);
  top: calc((44px - 20px) / 2);
  transform: rotate(-45deg);
}
.sheet-close:active {
  filter: brightness(0.8);
}

.sheet header h2 {
  vertical-align: middle;
  line-height: 1;
  margin-bottom: 8px;
}

#station .pill {
  margin-top: 2px;
  margin-bottom: 8px;
  cursor: default;
}

#station {
  display: flex;
  flex-direction: column;
  padding: 0;
  background-color: transparent;
  box-shadow: none;
  height: 50vh;
}
#station header {
  flex-shrink: 0;
}
#station header {
  position: relative;
  text-align: center;
  background-image: linear-gradient(
    to bottom,
    transparent,
    transparent 50%,
    #fff 50%
  );
  overflow: hidden;
  box-shadow: 0 8px 8px #fff;
}
#station header:after {
  content: '';
  position: absolute;
  width: 100%;
  height: 60%;
  background: #fff;
  top: 20%;
  left: 0;
  border-radius: 50%;
  z-index: -1;
  box-shadow: 0 0 20px rgba(0, 0, 0, 0.25);
}
#station .scrollable {
  padding: 0 16px 16px;
  background-color: #fff;
  flex-grow: 1;
  overflow: auto;
}

.pill {
  display: inline-flex;
  font-weight: bold;
  font-size: 16px;
  border-radius: 5px/50%;
  overflow: hidden;
  word-spacing: -0.2em;
  box-shadow: 0 0 0 1px #fff, 0 2px 4px 1px rgba(0, 0, 0, 0.2);
  text-shadow: 0 -1px rgba(0, 0, 0, 0.25);
}
.pill.mini {
  font-size: 11px;
}
.pill span {
  padding: 0.4em;
  color: #fff;
  background-color: #748477;
  background-image: linear-gradient(
    to bottom,
    rgba(255, 255, 255, 0.2),
    rgba(0, 0, 0, 0),
    rgba(0, 0, 0, 0.1)
  );
}
.pill span:first-child {
  padding-left: 0.5em;
}
.pill span:last-child {
  padding-right: 0.5em;
}
.pill span.red {
  background-color: #d42e12;
}
.pill span.green {
  background-color: #009645;
}
.pill span.yellow {
  background-color: #fa9e0d;
  color: #000;
  text-shadow: 0 -1px rgba(255, 255, 255, 0.5);
  background-image: linear-gradient(
    to bottom,
    rgba(255, 255, 255, 0.3),
    rgba(0, 0, 0, 0)
  );
}
.pill span.brown {
  background-color: #9d5b25;
}
.pill span.purple {
  background-color: #9900aa;
}
.pill span.blue {
  background-color: #005ec4;
}

.arrivals {
  background-color: aliceblue;
  padding: 12px 16px;
  border-radius: 12px;
  margin-top: 12px;
}
.arrivals:empty {
  display: none;
}
.arrivals table {
  width: 100%;
  line-height: 1.5em;
}
.arrivals table tbody tr td:nth-last-child(2),
.arrivals table tbody tr td:last-child {
  text-align: right;
}

.exits {
  margin-top: 12px;
  border-radius: 3px;
  background-color: #24201c;
  color: #feff65;
  padding: 12px;
  text-align: center;
  background-image: linear-gradient(180deg, #ffffff47, black);
  border: 2px solid black;
}
.sheet .exits h3 {
  color: #fffc;
  text-transform: uppercase;
  margin-top: -.5em;
  font-size: .85em;
}
.exit-btn {
  appearance: none;
  display: inline-block;
  margin: 3px;
  font-weight: bold;
  border: 0;
  border-radius: 3px;
  color: #000;
  background: #feff65 url(./exit.png) no-repeat;
  background-size: 100%;
  font-size: 16px;
  display: inline-block;
  width: 32px;
  height: 32px;
  line-height: 34px;
  cursor: pointer;
  padding: 0;
}
.exit-btn:hover {
  filter: saturate(2);
  transform: scale(1.1);
}
.exit-btn:active {
  filter: brightness(0.8);
  transform: scale(1);
}
.exits .note {
  color: #fffc;
  margin: .5em 0 0;
  padding: 0;
}

.wikipedia {
  margin-top: 12px;
  border-radius: 12px;
  overflow: hidden;
  background-color: aliceblue;
}
.wikipedia img {
  width: 100%;
  height: auto;
}
.wikipedia .extract {
  padding: 12px;
  line-height: 1.5em;
}

.wikipedia .extract p {
  margin-top: 0;
  padding-top: 0;
}
.wikipedia .extract p:last-child {
  margin-bottom: 0;
  padding-bottom: 0;
}

.wikipedia .more {
  padding: 0 16px 16px;
  text-align: right;
}

#search {
  position: fixed;
  z-index: 400;
  top: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(225, 225, 225, 0.95);
  flex-direction: column;
  user-select: none;
  -webkit-user-select: none;
}
#search:not([hidden]) {
  display: flex;
}
@supports (backdrop-filter: blur(10px)) {
  #search {
    background-color: rgba(225, 225, 225, 0.5);
    backdrop-filter: blur(20px) saturate(3);
    -webkit-backdrop-filter: blur(20px) saturate(3);
  }
}
@supports (-webkit-backdrop-filter: blur(10px)) {
  #search {
    background-color: rgba(225, 225, 225, 0.5);
    -webkit-backdrop-filter: blur(20px) saturate(3);
  }
}

#search-field-container {
  display: flex;
  flex-shrink: 0;
}
#search-field {
  outline: 0;
  flex-grow: 1;
  border-radius: 9px;
  font-size: 18px;
  margin: 16px 0 16px 16px;
  padding: 8px;
  border: 0;
  background-color: rgba(0, 0, 0, 0.1);
  transition: background-color 0.3s;
  appearance: none;
  -webkit-appearance: none;
}
#search-field:focus {
  background-color: rgba(255, 255, 255, 0.5);
  /* blue outline */
  outline: 3px solid #007bffcc;
}
#search-cancel {
  border: 0;
  padding: 16px;
  background-color: transparent;
  appearance: none;
  -webkit-appearance: none;
  font-size: 16px;
  color: slategray;
  outline: 0;
  cursor: pointer;
}

#search-results {
  margin: 0;
  padding: 0;
  list-style: none;
  overflow: auto;
}

#search-results li {
  padding: 0;
  margin: 0;
}

#search-results li a {
  color: inherit;
  display: block;
  padding: 16px;
  font-weight: bold;
  position: relative;
  background-image: none;
  transition: background-color 0.3s;
  outline: 0;
}

#search-results li a:first-child {
  background-color: rgba(255, 255, 255, 0.5);
}
#search-results li a * {
  pointer-events: none;
}
#search-results li a:hover {
  background-color: #fff;
}
#search-results li a:after {
  content: '';
  display: block;
  width: calc(100% - 32px);
  position: absolute;
  left: 16px;
  bottom: 0;
  border-bottom: 1px solid rgba(255, 255, 255, 0.75);
}
#search-results .pill {
  float: right;
  margin-top: -2px;
  margin-left: 0.5em;
  margin-right: -1px;
}

/* @media (min-width: 600px) and (max-width: 799px) {
  .wikipedia img {
    float: right;
    width: 50%;
    border-radius: 12px;
    margin: 16px;
  }
} */

.crowd-marker {
  pointer-events: none;
}
.crowd-marker:after {
  content: '';
  display: block;
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background-color: orangered;
  border-radius: 50%;
  animation: pulsing-h 2s infinite ease-out backwards;
  will-change: transform, opacity, width, height, margin;
}
.crowd-marker.large:after {
  width: 200%;
  height: 200%;
  margin: -50%;
}
.crowd-marker.larger:after {
  width: 400%;
  height: 400%;
  margin: -150%;
}
.crowd-marker-m:after {
  transform: scale(0.5);
  background-color: orange;
  animation-name: pulsing-m;
  animation-duration: 5s;
}
@keyframes pulsing-m {
  0% {
    transform: scale(0);
    opacity: 0.75;
  }
  100% {
    transform: scale(0.8);
    opacity: 0;
  }
}
@keyframes pulsing-h {
  0% {
    transform: scale(0);
    opacity: 0.75;
  }
  100% {
    transform: scale(1);
    opacity: 0;
  }
}
.crowd-marker:nth-child(even):after {
  animation-delay: 0.5s;
}

#legend {
  position: fixed;
  z-index: 1;
  bottom: 40px;
  left: 10px;
  background-color: rgba(255, 255, 255, 0.5);
  padding: 0.6em;
  border-radius: 12px;
  max-width: calc(100% - 100px);
  backdrop-filter: blur(20px) saturate(3);
  -webkit-backdrop-filter: blur(20px) saturate(3);
  border: 1px solid rgba(255, 255, 255, 0.25);
}

#legend summary {
  font-weight: bold;
  outline: 0;
  cursor: pointer;
}
#legend summary ~ div {
  vertical-align: middle;
  margin-top: 0.25em;
}

#legend img {
  vertical-align: middle;
}
#legend .crowd-marker {
  width: 16px;
  height: 16px;
  position: relative;
  display: inline-block;
  vertical-align: middle;
  background-color: #fff;
  border-radius: 50%;
}

#crowded-timing {
  font-size: 11px;
  text-transform: uppercase;
  margin: 3px;
  color: #666;
}
#crowded-timing b {
  display: inline-block;
}

@media (min-width: 640px) {
  .sheet {
    width: 320px;
    top: 0;
    right: 100%;
    bottom: 0;
  }
  .sheet.open,
  .sheet.open.min {
    transform: translateX(100%);
    box-shadow: 5px 0 rgba(0, 0, 0, 0.1) !important;
  }
  .sheet.modal.open {
    box-shadow: 0 0 50vh rgba(0, 0, 0, 0.5) !important;
  }
  #station {
    height: auto;
  }
  #station header {
    background: #fff none;
    padding-top: 16px;
  }
  .sheet-close {
    top: 16px;
    left: 0;
  }
  .sheet.open ~ .sheet-close {
    transform: translateX(320px) translateX(-50%) !important;
  }

  #search {
    align-items: center;
  }
  #search-field-container,
  #search-results {
    width: 480px;
  }
}
